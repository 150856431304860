import initialState from './initialState';
import {
  RECEIVE_FILE_TRANSFER_DETAILS,
  REQUEST_FILE_TRANSFER_DETAILS
} from '../actions/fileTransferDetailsActions';
import {
  CLEAR_CURRENT_VENDOR_FILES_LIST,
  RECEIVE_832_FILES, RECEIVE_MASS_AGREEMENT_FILE_IMPORT,
  RECEIVE_VENDOR_ASSOCIATION_FILE_IMPORT,
  RECEIVE_VENDOR_FILE_REIMPORT_USING_VARIATION_REPORT,
  REQUEST_832_FILES, REQUEST_MASS_AGREEMENT_FILE_IMPORT,
  REQUEST_VENDOR_ASSOCIATION_FILE_IMPORT,
  REQUEST_VENDOR_FILE_REIMPORT_USING_VARIATION_REPORT,
} from '../actions/filesActions';
import {
  REQUEST_COST_DATA,
  RECEIVE_COST_DATA,
  REQUEST_BUSINESS_CENTER_DETAILS,
  RECEIVE_BUSINESS_CENTER_DETAILS,
  REQUEST_PRICE_RECORD_TYPES,
  RECEIVE_PRICE_RECORD_TYPES,
  REQUEST_ADHOC_COST_FILE_IMPORT,
  SUBMIT_GENERATE_COST_REPORTS,
  RECEIVE_ADHOC_COST_FILE_IMPORT_RESPONSE,
  REQUEST_MULTIPLE_ADHOC_COST_FILE_IMPORT,
  RECEIVE_MULTIPLE_ADHOC_COST_FILE_IMPORT_RESPONSE
} from '../actions/costMaintenanceActions';
import {
  RECEIVE_ERROR_IN_832_FILES_FETCH,
  RECEIVE_ERROR_IN_FILE_TRANSFER_DETAILS_FETCH,
  RECEIVE_ERROR_IN_REPORT_DETAILS_FETCH,
  RECEIVE_ERROR_IN_USER_PROFILE_DETAILS_FETCH,
  RECEIVE_ERROR_IN_USER_GROUP_DETAILS_FETCH,
  RECEIVE_ERROR_IN_USER_PROFILE_DETAILS_FETCH_OF_SELECTED_USER,
  RECEIVE_ERROR_IN_USERS_FETCH,
  RECEIVE_ERROR_IN_ALL_USER_GROUPS_FETCH,
  RECEIVE_ERROR_IN_VENDOR_FILE_IMPORT,
  RECEIVE_ERROR_IN_VENDOR_FILE_LOG_FETCH,
  RECEIVE_ERROR_IN_BUSINESS_CENTERS_FETCH,
  RECEIVE_ERROR_IN_PRICE_RECORD_TYPES_FETCH,
  RECEIVE_ERROR_IN_VENDORS_FETCH,
  RECEIVE_ERROR_IN_SUPPLIER_SUPPORT_REQUEST_SUBMISSION,
  RECEIVE_ERROR_IN_HELP_TOPIC_CONTENT_FETCH,
  RECEIVE_ERROR_IN_REQUEST_DETAILS_FETCH,
  RECEIVE_ERROR_IN_WORKSPACE_REQUEST_SUBMISSION,
  RECEIVE_ERROR_IN_COST_DATA_FETCH,
  RECEIVE_ERROR_IN_ADHOC_COST_FILE_IMPORT,
  RECEIVE_ERROR_IN_REQUEST_STATUS_UPDATE,
  RECEIVE_ERROR_IN_WORKSPACE_REQUEST_ACTION_CHANGE,
  RECEIVE_ERROR_IN_WORKSPACE_REQUEST_COMMENT_SUBMISSION,
  RECEIVE_ERROR_WHEN_SAVING_USER_MANAGEMENT_DETAILS,
  RECEIVE_ERROR_IN_ENTERPRISE_COST_MASTER_DATA_FETCH,
  RECEIVE_ERROR_IN_ENTERPRISE_COST_DATA_FETCH,
  RECEIVE_ERROR_IN_EARNED_INCOME_MAPPINGS_FETCH,
  RECEIVE_ERROR_IN_SAS_FD_DETAILS_FETCH,
  RECEIVE_ERROR_WHEN_SAVING_VENDOR_SAS_DETAILS,
  RECEIVE_ERROR_WHEN_ADDING_VENDOR_SAS_DETAILS,
  RECEIVE_ERROR_WHEN_SAVING_ITEM_SAS_FD_DETAILS,
  RECEIVE_ERROR_IN_SAS_FD_FILE_IMPORT,
  RECEIVE_ERROR_IN_SUPPLIER_ONBOARD_DETAILS_FETCH,
  RECEIVE_ERROR_IN_USER_ASSOCIATION_AND_BUSINESS_CENTER_CONTACTS_FETCH,
  RECEIVE_ERROR_UPDATE_SUPPLIER_CONTACT,
  RECEIVE_ERROR_IN_AGREEMENTS_SEARCH_DATA_FETCH,
} from '../actions/errorDialogActions';
import {
  RECEIVE_VENDOR_PRICE_FILES_IMPORT_LOG,
  REQUEST_VENDOR_PRICE_FILES_IMPORT_LOG
} from '../actions/filesLogActions';
import {
  DELETE_REPORT_TEMPLATE,
  RECEIVE_COST_AUDIT_REPORT_DATA, RECEIVE_REPORT_TEMPLATE_DELETE_STATUS, RECEIVE_REPORT_TEMPLATE_DETAILS,
  RECEIVE_REPORTS_LOG, REQUEST_COST_AUDIT_REPORT_DATA, REQUEST_REPORT_TEMPLATE_DETAILS, REQUEST_REPORTS_LOG,
} from '../actions/reportsActions';
import {
  FETCH_USERS,
  RECEIVE_USERS,
  FETCH_ALL_USER_GROUPS,
  RECEIVE_ALL_USER_GROUPS,
  RECEIVE_USER_PROFILE_DETAILS,
  REQUEST_USER_PROFILE_DETAILS,
  REQUEST_USER_PROFILE_MANAGEMENT_DETAILS,
  REQUEST_USER_GROUP_DETAILS,
  RECEIVE_USER_GROUP_DETAILS,
  RECEIVE_USER_PROFILE_MANAGEMENT_DETAILS,
  SAVE_SUPERVISOR_FOR_USER,
  RECEIVE_SUPERVISOR_PERSIST_STATUS_FOR_USER,
  SAVE_VENDORS_ASSOCIATION_FOR_USER,
  SAVE_GROUP,
  SAVE_DIRECT_USER_GROUP_ASSOCIATION,
  RECEIVE_VENDOR_ASSOCIATION_PERSIST_STATUS_FOR_USER,
  RECEIVE_GROUP_PERSIST_STATUS,
  RECEIVE_DIRECT_USER_GROUP_ASSOCIATION_PERSIST_STATUS,
  REMOVE_SUPERVISOR_OF_USER,
  RECEIVE_SUPERVISOR_REMOVE_STATUS_FOR_USER,
  REQUEST_USER_PROFILE_DETAILS_OF_SELECTED_USER,
  RECEIVE_USER_PROFILE_DETAILS_OF_SELECTED_USER,
  REQUEST_USER_ASSOCIATION_AND_BUSINESS_CENTER_CONTACTS,
  RECEIVE_USER_ASSOCIATION_AND_BUSINESS_CENTER_CONTACTS,
  REQUEST_SUPPLIER_ONBOARD_DETAILS,
  RECEIVE_SUPPLIER_ONBOARD_DETAILS,
  RECEIVE_SUPPLIER_PERSIST_STATUS,
  RECEIVE_SUPPLIER_UPDATE_STATUS,
  SAVE_SUPPLIER,
  UPDATE_SUPPLIER,
  TRIGGER_UPDATE_SUPPLIER_CONTACT,
  SUCCESS_UPDATE_SUPPLIER_CONTACT
} from '../actions/userManagementActions';
import {
  RECEIVE_ALL_VENDORS,
  REQUEST_ALL_VENDORS,
  REQUEST_ALL_OPCOS,
  RECEIVE_ALL_OPCOS,
  RECEIVE_OPCO_FETCH_ERROR
} from '../actions/vendorActions';
import {
  REQUEST_ENTERPRISE_COST_MASTER_DATA,
  REQUEST_ENTERPRISE_COST_DATA,
  RECEIVE_ENTERPRISE_COST_MASTER_DATA,
  RECEIVE_ENTERPRISE_COST_DATA
} from '../actions/enterpriseCostActions';
import {
  SUBMIT_SUPPLIER_SUPPORT_REQUEST,
  RECEIVE_SUPPLIER_SUPPORT_REQUEST_SUBMISSION_RESPONSE,
  REQUEST_HELP_TOPIC_CONTENT,
  RECEIVE_HELP_TOPIC_CONTENT,
  FETCH_REQUEST_DETAILS,
  RECEIVE_REQUEST_DETAILS,
  SUBMIT_WORKSPACE_REQUEST,
  RECEIVE_WORKSPACE_REQUEST_SUBMISSION_RESPONSE,
  UPDATE_SELECTED_REQUEST_STATUS,
  SUBMIT_WORKSPACE_REQUEST_ACTION_CHANGE,
  RECEIVE_WORKSPACE_REQUEST_ACTION_RESPONSE,
  ADD_WORKSPACE_REQUEST_COMMENT
} from '../actions/workspaceActions';
import {
  FETCH_EARNED_INCOME_MAPPINGS,
  RECEIVE_EARNED_INCOME_MAPPINGS
} from '../actions/utilitiesActions';
import {
  FETCH_VENDOR_BASES,
  RECEIVE_VENDOR_BASES,
  FETCH_BUSINESS_CENTERS,
  RECEIVE_BUSINESS_CENTERS,
  FETCH_SAS_FD_DETAILS,
  RECEIVE_SAS_FD_DETAILS,
  SAVE_SAS_VALUE_FOR_VENDOR,
  RECEIVE_SAS_VALUE_UPDATE_STATUS_FOR_VENDOR,
  ADD_SAS_VALUE_FOR_VENDOR,
  RECEIVE_SAS_VALUE_ADD_STATUS_FOR_VENDOR,
  REQUEST_SAS_FD_FILE_IMPORT,
  RECEIVE_SAS_FD_FILE_IMPORT,
  SAVE_SAS_FD_DETAILS_FOR_ITEM,
  RECEIVE_SAS_FD_DETAILS_UPDATE_STATUS_FOR_ITEM,
  FETCH_AGREEMENTS_SEARCH_DATA,
  RECEIVE_AGREEMENTS_SEARCH_DATA,
} from '../actions/agreementActions';

export default function loaderReducer(state, { type }) {
  if (typeof state === 'undefined') {
    return initialState.loader;
  }
  switch (type) {
    case RECEIVE_FILE_TRANSFER_DETAILS:
    case RECEIVE_832_FILES:
    case RECEIVE_VENDOR_ASSOCIATION_FILE_IMPORT:
    case RECEIVE_MASS_AGREEMENT_FILE_IMPORT:
    case CLEAR_CURRENT_VENDOR_FILES_LIST:
    case RECEIVE_ERROR_IN_VENDOR_FILE_IMPORT:
    case RECEIVE_VENDOR_PRICE_FILES_IMPORT_LOG:
    case RECEIVE_ERROR_IN_FILE_TRANSFER_DETAILS_FETCH:
    case RECEIVE_ERROR_IN_VENDOR_FILE_LOG_FETCH:
    case RECEIVE_ERROR_IN_REPORT_DETAILS_FETCH:
    case RECEIVE_ERROR_IN_832_FILES_FETCH:
    case RECEIVE_VENDOR_FILE_REIMPORT_USING_VARIATION_REPORT:
    case RECEIVE_REPORTS_LOG:
    case RECEIVE_USER_PROFILE_DETAILS:
    case RECEIVE_ERROR_IN_USER_PROFILE_DETAILS_FETCH:
    case RECEIVE_ERROR_IN_USER_GROUP_DETAILS_FETCH:
    case RECEIVE_USER_PROFILE_MANAGEMENT_DETAILS:
    case RECEIVE_USERS:
    case RECEIVE_ALL_USER_GROUPS:
    case RECEIVE_SUPERVISOR_PERSIST_STATUS_FOR_USER:
    case RECEIVE_SUPERVISOR_REMOVE_STATUS_FOR_USER:
    case RECEIVE_VENDOR_ASSOCIATION_PERSIST_STATUS_FOR_USER:
    case RECEIVE_GROUP_PERSIST_STATUS:
    case RECEIVE_DIRECT_USER_GROUP_ASSOCIATION_PERSIST_STATUS:
    case RECEIVE_USER_GROUP_DETAILS:
    case RECEIVE_USER_PROFILE_DETAILS_OF_SELECTED_USER:
    case RECEIVE_ERROR_IN_USER_PROFILE_DETAILS_FETCH_OF_SELECTED_USER:
    case RECEIVE_ERROR_IN_USERS_FETCH:
    case RECEIVE_ERROR_IN_ALL_USER_GROUPS_FETCH:
    case RECEIVE_ALL_VENDORS:
    case RECEIVE_ALL_OPCOS:
    case RECEIVE_ERROR_IN_VENDORS_FETCH:
    case RECEIVE_OPCO_FETCH_ERROR:
    case RECEIVE_ERROR_IN_BUSINESS_CENTERS_FETCH:
    case RECEIVE_PRICE_RECORD_TYPES:
    case RECEIVE_ERROR_IN_PRICE_RECORD_TYPES_FETCH:
    case RECEIVE_COST_AUDIT_REPORT_DATA:
    case RECEIVE_REPORT_TEMPLATE_DETAILS:
    case RECEIVE_REPORT_TEMPLATE_DELETE_STATUS:
    case RECEIVE_ERROR_IN_SUPPLIER_SUPPORT_REQUEST_SUBMISSION:
    case RECEIVE_SUPPLIER_SUPPORT_REQUEST_SUBMISSION_RESPONSE:
    case RECEIVE_HELP_TOPIC_CONTENT:
    case RECEIVE_ERROR_IN_HELP_TOPIC_CONTENT_FETCH:
    case RECEIVE_REQUEST_DETAILS:
    case RECEIVE_ERROR_IN_REQUEST_DETAILS_FETCH:
    case RECEIVE_WORKSPACE_REQUEST_SUBMISSION_RESPONSE:
    case RECEIVE_ERROR_IN_WORKSPACE_REQUEST_SUBMISSION:
    case RECEIVE_ERROR_IN_REQUEST_STATUS_UPDATE:
    case RECEIVE_WORKSPACE_REQUEST_ACTION_RESPONSE:
    case RECEIVE_ERROR_IN_WORKSPACE_REQUEST_ACTION_CHANGE:
    case RECEIVE_COST_DATA:
    case RECEIVE_ERROR_IN_COST_DATA_FETCH:
    case RECEIVE_ADHOC_COST_FILE_IMPORT_RESPONSE:
    case RECEIVE_MULTIPLE_ADHOC_COST_FILE_IMPORT_RESPONSE:
    case RECEIVE_ERROR_IN_ADHOC_COST_FILE_IMPORT:
    case RECEIVE_ERROR_IN_WORKSPACE_REQUEST_COMMENT_SUBMISSION:
    case RECEIVE_ENTERPRISE_COST_MASTER_DATA:
    case RECEIVE_ENTERPRISE_COST_DATA:
    case RECEIVE_ERROR_IN_ENTERPRISE_COST_MASTER_DATA_FETCH:
    case RECEIVE_ERROR_IN_ENTERPRISE_COST_DATA_FETCH:
    case RECEIVE_EARNED_INCOME_MAPPINGS:
    case RECEIVE_ERROR_IN_EARNED_INCOME_MAPPINGS_FETCH:
    case RECEIVE_ERROR_IN_SAS_FD_DETAILS_FETCH:
    case RECEIVE_VENDOR_BASES:
    case RECEIVE_BUSINESS_CENTERS:
    case RECEIVE_SAS_FD_DETAILS:
    case RECEIVE_SAS_VALUE_UPDATE_STATUS_FOR_VENDOR:
    case RECEIVE_ERROR_WHEN_SAVING_VENDOR_SAS_DETAILS:
    case RECEIVE_SAS_VALUE_ADD_STATUS_FOR_VENDOR:
    case RECEIVE_ERROR_WHEN_ADDING_VENDOR_SAS_DETAILS:
    case RECEIVE_ERROR_WHEN_SAVING_ITEM_SAS_FD_DETAILS:
    case RECEIVE_SAS_FD_FILE_IMPORT:
    case RECEIVE_SAS_FD_DETAILS_UPDATE_STATUS_FOR_ITEM:
    case RECEIVE_ERROR_IN_SAS_FD_FILE_IMPORT:
    case RECEIVE_AGREEMENTS_SEARCH_DATA:
    case RECEIVE_ERROR_IN_AGREEMENTS_SEARCH_DATA_FETCH:
      return { ...state, show: false };

    case RECEIVE_SUPPLIER_ONBOARD_DETAILS:
    case RECEIVE_SUPPLIER_UPDATE_STATUS:
    case RECEIVE_SUPPLIER_PERSIST_STATUS:
    case RECEIVE_ERROR_WHEN_SAVING_USER_MANAGEMENT_DETAILS:
    case RECEIVE_ERROR_IN_SUPPLIER_ONBOARD_DETAILS_FETCH:
    case RECEIVE_ERROR_IN_USER_ASSOCIATION_AND_BUSINESS_CENTER_CONTACTS_FETCH:
    case SUCCESS_UPDATE_SUPPLIER_CONTACT:
    case RECEIVE_ERROR_UPDATE_SUPPLIER_CONTACT:
      return {
        ...state,
        show: false,
      };
    case RECEIVE_USER_ASSOCIATION_AND_BUSINESS_CENTER_CONTACTS:
      return { ...state, show: false };
    case RECEIVE_BUSINESS_CENTER_DETAILS:
      return { ...state, show: false, isBusinessCenterLoading: false };
    case REQUEST_VENDOR_ASSOCIATION_FILE_IMPORT:
    case REQUEST_MASS_AGREEMENT_FILE_IMPORT:
    case REQUEST_VENDOR_FILE_REIMPORT_USING_VARIATION_REPORT:
      return {
        ...state, type: 'modal', show: true
      };

    case REQUEST_COST_AUDIT_REPORT_DATA:
      return {
        ...state, type: 'modal', show: true, message: 'Creating report... this might take a while'
      };

    case REQUEST_MULTIPLE_ADHOC_COST_FILE_IMPORT:
    case REQUEST_ADHOC_COST_FILE_IMPORT:
      return {
        ...state, type: 'modal', show: true, message: 'Importing cost data from file... this might take a while'
      };

    case REQUEST_COST_DATA:
      return {
        ...state, type: 'modal', show: true, message: 'Loading cost data...'
      };

    case FETCH_USERS:
    case FETCH_ALL_USER_GROUPS:
    case REQUEST_USER_PROFILE_MANAGEMENT_DETAILS:
    case REQUEST_ALL_VENDORS:
    case REQUEST_ALL_OPCOS:
    case REQUEST_REPORT_TEMPLATE_DETAILS:
    case REQUEST_USER_GROUP_DETAILS:
    case FETCH_REQUEST_DETAILS:
    case REQUEST_ENTERPRISE_COST_MASTER_DATA:
    case REQUEST_ENTERPRISE_COST_DATA:
    case FETCH_EARNED_INCOME_MAPPINGS:
    case FETCH_VENDOR_BASES:
    case FETCH_SAS_FD_DETAILS:
    case FETCH_BUSINESS_CENTERS:
      return {
        ...state, type: 'modal', show: true, message: 'Loading...'
      };

    case REQUEST_SUPPLIER_ONBOARD_DETAILS:
    case REQUEST_USER_ASSOCIATION_AND_BUSINESS_CENTER_CONTACTS:
      return {
        ...state,
        type: 'modal',
        show: true,
        message: 'Loading user associations...',
      };

    case REQUEST_BUSINESS_CENTER_DETAILS:
      return {
        ...state, type: 'modal', show: true, message: 'Loading business centers...', isBusinessCenterLoading: true
      };

    case FETCH_AGREEMENTS_SEARCH_DATA:
      return {
        ...state, type: 'modal', show: true, message: 'Loading agreements...'
      };

    case REQUEST_PRICE_RECORD_TYPES:
      return {
        ...state, type: 'modal', show: true, message: 'Loading price record types...'
      };

    case SUBMIT_SUPPLIER_SUPPORT_REQUEST:
      return {
        ...state, type: 'modal', show: true, message: 'Submitting Supplier Support Request...'
      };

    case SUBMIT_WORKSPACE_REQUEST:
      return {
        ...state, type: 'modal', show: true, message: 'Submitting Request...'
      };

    case UPDATE_SELECTED_REQUEST_STATUS:
      return {
        ...state, type: 'modal', show: true, message: 'Updating Request Status...'
      };

    case SUBMIT_WORKSPACE_REQUEST_ACTION_CHANGE:
      return {
        ...state, type: 'modal', show: true, message: 'Updating Request...'
      };

    case SUBMIT_GENERATE_COST_REPORTS:
      return {
        ...state, type: 'modal', show: true, message: 'Generating Variance Reports...'
      };

    case ADD_WORKSPACE_REQUEST_COMMENT:
      return {
        ...state, type: 'modal', show: true, message: 'Posting message...'
      };

    case SAVE_SUPERVISOR_FOR_USER:
    case REMOVE_SUPERVISOR_OF_USER:
    case SAVE_VENDORS_ASSOCIATION_FOR_USER:
    case SAVE_GROUP:
    case SAVE_DIRECT_USER_GROUP_ASSOCIATION:
    case SAVE_SAS_VALUE_FOR_VENDOR:
    case ADD_SAS_VALUE_FOR_VENDOR:
    case SAVE_SAS_FD_DETAILS_FOR_ITEM:
      return {
        ...state, type: 'modal', show: true, message: 'Saving...'
      };

    case DELETE_REPORT_TEMPLATE:
      return {
        ...state, type: 'modal', show: true, message: 'Deleting...'
      };

    case REQUEST_SAS_FD_FILE_IMPORT:
      return {
        ...state, type: 'modal', show: true, message: 'Uploading SAS/FD File...'
      };

    case REQUEST_FILE_TRANSFER_DETAILS:
    case REQUEST_832_FILES:
    case REQUEST_REPORTS_LOG:
    case REQUEST_VENDOR_PRICE_FILES_IMPORT_LOG:
    case REQUEST_USER_PROFILE_DETAILS:
    case REQUEST_USER_PROFILE_DETAILS_OF_SELECTED_USER:
    case REQUEST_HELP_TOPIC_CONTENT:
      return { ...state, type: 'spinner', show: true };

    case UPDATE_SUPPLIER:
    case SAVE_SUPPLIER:
      return {
        ...state, type: 'modal', show: true, message: 'Saving Supplier Profile...',
      };
    case TRIGGER_UPDATE_SUPPLIER_CONTACT:
      return {
        ...state, type: 'modal', show: true, message: 'Updating Supplier Contact Details...',
      };
    default:
      return state;
  }
}
